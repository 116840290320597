<script setup>
/******************************************************************************
 * component: Header title bar
 *****************************************************************************/
import AppHeaderLogout from "./AppHeaderLogout.vue";
import AppHeaderOfflineNotification from "./AppHeaderOfflineNotification.vue";
import { APP_SHORT_NAME } from "@/config";
import { useAppStore } from "Stores/app";
import { useReadingStore } from "Stores/reading";
const appStore = useAppStore();
const data = useReadingStore();
</script>

<template>
    <div class="titlebar">
        <a
            v-if="
                data.customers.length > 0 && data.customers[0].clientId === 23
            "
            href="https://www.philippsthal.de"
            target="_blank"
        >
            <img
                class="logo"
                src="./logo23.png"
                alt="Gemeinde Philipstal (Wera)"
            />
        </a>
        <img
            v-if="
                data.customers.length > 0 && data.customers[0].clientId === 22
            "
            class="logo"
            src="./logo22.png"
            alt="WAZV"
        />
        <h1>{{ APP_SHORT_NAME }}</h1>
        <div v-if="!appStore.online || true" class="notifications">
            <app-header-offline-notification v-if="!appStore.online" />
            <app-header-logout v-if="data.readings.length > 0" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.titlebar {
    display: flex;
    align-items: center;

    padding: #{get($sizes, "base") * 0.25} #{get($sizes, "base") * 0.5};
    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 1};
        gap: get($sizes, "base");
    }
}

.logo {
    height: get($client, "headerTitleBarHeight");

    @include respond-to("large") {
        height: get($client, "headerTitleBarHeightLarge");
    }
}

h2 {
    margin: 0;

    font-size: #{get($sizes, "base") * 0.8};
    font-weight: 400;
    line-height: 1.4;

    @include respond-to("small") {
        font-size: #{get($sizes, "base") * 0.9};
        font-weight: 300;
    }

    @include respond-to("medium") {
        font-size: #{get($sizes, "base") * 1};
    }

    > span {
        display: none;

        @include respond-to("medium") {
            display: block;
        }
    }
}

h1 {
    margin: 0;

    font-size: #{get($sizes, "base") * 1.1};
    font-weight: 400;
    line-height: get($client, "headerTitleBarHeight");

    @include respond-to("small") {
        font-size: #{get($sizes, "base") * 1.5};
        font-weight: 300;
    }

    @include respond-to("medium") {
        font-size: #{get($sizes, "base") * 2};
    }

    @include respond-to("large") {
        font-size: #{get($sizes, "base") * 2.5};
    }
}

.notifications {
    display: flex;

    margin-left: auto;
    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        gap: get($sizes, "base");
    }
}
</style>
