<script setup>
/******************************************************************************
 * Component footer
 *****************************************************************************/
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
</script>

<template>
    <footer>
        <nav v-if="data.readings.length > 0">
            <a class="footer-link" :href="data.privacyUrl" target="_blank"
                >Datenschutz</a
            >
            <a class="footer-link" :href="data.imprintUrl" target="_blank"
                >Impressum</a
            >
        </nav>
        <nav v-else>
            <a
                class="footer-link"
                href="https://www.philippsthal.de/datenschutz.html"
                target="_blank"
                >Datenschutz Philippsthal</a
            >
            <a class="footer-link" href="/datenschutz-wazv.pdf" target="_blank"
                >Datenschutz WAZV</a
            >
            <a class="footer-link" :href="data.imprintUrl" target="_blank"
                >Impressum</a
            >
        </nav>
    </footer>
</template>

<style lang="scss" scoped>
footer {
    border-top: get($client, "footerBorder");

    background-color: get($client, "footerBg");

    @include respond-to("medium") {
        display: flex;
        justify-content: space-between;
    }

    a {
        display: inline-block;
        position: relative;

        padding: #{get($sizes, "base") * 0.5};

        color: get($client, "footerColor");

        transition: background-color get($timings, "fast") linear;

        &:hover {
            background-color: color.adjust(
                get($client, "footerBg"),
                $lightness: -10%
            );
        }
    }
}

nav a:not(:first-child)::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;

    width: 1px;
    height: #{get($sizes, "base") * 1.25};

    transform: translateY(-50%);

    background: color.adjust(get($client, "footerColor"), $lightness: -20%);

    content: "";
}
</style>
