/******************************************************************************
 * store for meter reading data
 *****************************************************************************/
import { defineStore } from "pinia";
import { actions } from "./actions.js";
import { getters } from "./getters.js";
import { format } from "date-fns";

/**
 * @function store definition
 */
export const useReadingStore = defineStore("reading", {
    state: () => {
        return {
            config: {},
            meters: [],
            customers: [],
            readings: [],
            dateAndReasonType: "global", // "individual" || "global"
            globalReadingDate: format(new Date(), "dd.MM.yyyy"),
            globalReadingReason: "Jahresablesung", // value, not label. must match MeterReading/DateAndReasonType/ReadingReason.vue
            printFile: "",
            error: "",
            queuedReading: false,
            // beste reactive footer URLs
            privacyUrl: "https://www.eam-netz.de/datenschutzerklaerung/",
            imprintUrl: "https://www.eam-netz.de/impressum/",
        };
    },

    getters,
    actions,

    persist: {
        key: "ivu-netzableseportal-reading",
        storage: sessionStorage,
    },
});
